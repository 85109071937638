import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import * as Herz from '@rushplay/herz'

import * as Player from './player'
import { useServerConfiguration } from './server-configuration'

export function ChatProvider({ children, ...props }) {
  const translate = Herz.I18n.useTranslate()

  const { liveChatDepartment, liveChatKey, locale } = useServerConfiguration()

  const player = ReactRedux.useSelector(state => ({
    birthdate: Player.getBirthdate(state.player),
    valueSegment: Player.getValueSegment(state.player),
    name: Player.getDisplayName(state.player),
    username: Player.getUsername(state.player),
    email: Player.getEmail(state.player),
  }))

  const data = React.useMemo(
    () => ({
      birthDate: player.birthDate ? `DOB: ${player.birthdate}` : null,
      departmentId: R.pathOr(null, ['primary'], liveChatDepartment),
      fallbackDepartmentId: R.pathOr(null, ['fallback'], liveChatDepartment),
      name: player.name ? `${player.name} (${player.username})` : '',
      tags: R.filter(item => !R.isNil(item), [
        player.birthdate ? `DOB: ${player.birthdate}` : null,
        player.valueSegment ? `Value segment: ${player.valueSegment}` : null,
      ]),
    }),
    [
      player.name,
      player.username,
      player.birthdate,
      player.valueSegment,
      liveChatDepartment,
    ]
  )

  const translations = React.useMemo(
    () => ({
      'chat.concierge.avatarPath': translate('chat.concierge.avatar'),
      'chat.concierge.name': translate('chat.concierge.name'),
      'chat.concierge.title': translate('chat.concierge.title'),
      'chat.offlineForm.greeting': translate('chat.offline-form.greeting'),
      'chat.title': translate('chat.title'),
      'launcher.chatLabel': translate('chat.launcher.chat-label'),
      'launcher.label': translate('chat.launcher.label'),
    }),
    [
      translate('chat.concierge.avatar'),
      translate('chat.concierge.name'),
      translate('chat.concierge.title'),
      translate('chat.offline-form.greeting'),
      translate('chat.title'),
      translate('chat.launcher.chat-label'),
      translate('chat.launcher.label'),
    ]
  )

  if (!liveChatKey) {
    return null
  }

  return (
    <Herz.Chat.ChatProvider
      {...props}
      addScriptInHead
      appKey={liveChatKey}
      departmentId={data.departmentId}
      email={player.email}
      fallbackDepartmentId={data.fallbackDepartmentId}
      language={locale.language}
      name={data.name}
      tags={data.tags}
      hidden={props.hidden}
      translations={translations}
    >
      {children}
    </Herz.Chat.ChatProvider>
  )
}

Herz.I18n.Loader.preload(
  [
    'chat.concierge.avatar',
    'chat.concierge.name',
    'chat.concierge.title',
    'chat.offline-form.greeting',
    'chat.title',
    'chat.launcher.chat-label',
    'chat.launcher.label',
  ],
  ChatProvider
)

ChatProvider.defaultProps = {
  hidden: true,
}

ChatProvider.propTypes = {
  children: PropTypes.node,
  hidden: PropTypes.bool,
}

export function Chat() {
  const { authenticated } = Herz.Auth.useSession()
  const [allowLoad, setAllowLoad] = React.useState(false)
  const context = Herz.Chat.useContext()
  const visible = context.showWidget
  const location = ReactRouter.useLocation()

  React.useEffect(() => {
    if (visible || authenticated) {
      setAllowLoad(true)
    }
  }, [visible, authenticated])

  if (!location.pathname.includes('/faq')) {
    return null
  }

  if (allowLoad) {
    return <ChatScripts />
  }

  return null
}

function ChatScripts() {
  const context = Herz.Chat.useContext()

  return (
    <Helmet>
      <script type="text/javascript">
        {`window.zESettings = ${JSON.stringify(context.initialSettings)}`}
      </script>
      <script {...context.zendeskScript()} />
    </Helmet>
  )
}
